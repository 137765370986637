import { DiIncomeBand } from 'interfaces/DiIncomeBand';

export const IncomeReplacementChart: DiIncomeBand[] = [
  {
    insurable_income: 15000,
    total_indemnity: 1005,
    individual_base: 305,
    individual_sbr: 700,
    individual_group_combo: 1005,
  },
  {
    insurable_income: 16000,
    total_indemnity: 1055,
    individual_base: 330,
    individual_sbr: 725,
    individual_group_combo: 1055,
  },
  {
    insurable_income: 17000,
    total_indemnity: 1105,
    individual_base: 380,
    individual_sbr: 725,
    individual_group_combo: 1105,
  },
  {
    insurable_income: 18000,
    total_indemnity: 1155,
    individual_base: 405,
    individual_sbr: 750,
    individual_group_combo: 1155,
  },
  {
    insurable_income: 19000,
    total_indemnity: 1215,
    individual_base: 465,
    individual_sbr: 750,
    individual_group_combo: 1215,
  },
  {
    insurable_income: 20000,
    total_indemnity: 1305,
    individual_base: 530,
    individual_sbr: 775,
    individual_group_combo: 1305,
  },
  {
    insurable_income: 22000,
    total_indemnity: 1405,
    individual_base: 605,
    individual_sbr: 800,
    individual_group_combo: 1405,
  },
  {
    insurable_income: 24000,
    total_indemnity: 1510,
    individual_base: 685,
    individual_sbr: 825,
    individual_group_combo: 1510,
  },
  {
    insurable_income: 26000,
    total_indemnity: 1660,
    individual_base: 810,
    individual_sbr: 850,
    individual_group_combo: 1660,
  },
  {
    insurable_income: 28000,
    total_indemnity: 1775,
    individual_base: 900,
    individual_sbr: 875,
    individual_group_combo: 1775,
  },
  {
    insurable_income: 30000,
    total_indemnity: 1890,
    individual_base: 990,
    individual_sbr: 900,
    individual_group_combo: 1895,
  },
  {
    insurable_income: 32000,
    total_indemnity: 2000,
    individual_base: 1090,
    individual_sbr: 910,
    individual_group_combo: 2010,
  },
  {
    insurable_income: 34000,
    total_indemnity: 2110,
    individual_base: 1190,
    individual_sbr: 920,
    individual_group_combo: 2140,
  },
  {
    insurable_income: 36000,
    total_indemnity: 2220,
    individual_base: 1290,
    individual_sbr: 930,
    individual_group_combo: 2275,
  },
  {
    insurable_income: 38000,
    total_indemnity: 2325,
    individual_base: 1385,
    individual_sbr: 940,
    individual_group_combo: 2380,
  },
  {
    insurable_income: 40000,
    total_indemnity: 2430,
    individual_base: 1480,
    individual_sbr: 950,
    individual_group_combo: 2490,
  },
  {
    insurable_income: 42000,
    total_indemnity: 2530,
    individual_base: 1550,
    individual_sbr: 980,
    individual_group_combo: 2595,
  },
  {
    insurable_income: 44000,
    total_indemnity: 2635,
    individual_base: 1625,
    individual_sbr: 1010,
    individual_group_combo: 2710,
  },
  {
    insurable_income: 46000,
    total_indemnity: 2730,
    individual_base: 1690,
    individual_sbr: 1040,
    individual_group_combo: 2815,
  },
  {
    insurable_income: 48000,
    total_indemnity: 2820,
    individual_base: 1750,
    individual_sbr: 1070,
    individual_group_combo: 2920,
  },
  {
    insurable_income: 50000,
    total_indemnity: 2925,
    individual_base: 1825,
    individual_sbr: 1100,
    individual_group_combo: 3050,
  },
  {
    insurable_income: 52000,
    total_indemnity: 3025,
    individual_base: 1910,
    individual_sbr: 1115,
    individual_group_combo: 3175,
  },
  {
    insurable_income: 54000,
    total_indemnity: 3120,
    individual_base: 1990,
    individual_sbr: 1130,
    individual_group_combo: 3285,
  },
  {
    insurable_income: 56000,
    total_indemnity: 3215,
    individual_base: 2070,
    individual_sbr: 1145,
    individual_group_combo: 3395,
  },
  {
    insurable_income: 58000,
    total_indemnity: 3310,
    individual_base: 2150,
    individual_sbr: 1160,
    individual_group_combo: 3510,
  },
  {
    insurable_income: 60000,
    total_indemnity: 3405,
    individual_base: 2230,
    individual_sbr: 1175,
    individual_group_combo: 3610,
  },
  {
    insurable_income: 62000,
    total_indemnity: 3500,
    individual_base: 2310,
    individual_sbr: 1190,
    individual_group_combo: 3790,
  },
  {
    insurable_income: 64000,
    total_indemnity: 3600,
    individual_base: 2395,
    individual_sbr: 1205,
    individual_group_combo: 3935,
  },
  {
    insurable_income: 66000,
    total_indemnity: 3675,
    individual_base: 2455,
    individual_sbr: 1220,
    individual_group_combo: 4050,
  },
  {
    insurable_income: 68000,
    total_indemnity: 3765,
    individual_base: 2530,
    individual_sbr: 1235,
    individual_group_combo: 4205,
  },
  {
    insurable_income: 70000,
    total_indemnity: 3850,
    individual_base: 2600,
    individual_sbr: 1250,
    individual_group_combo: 4325,
  },
  {
    insurable_income: 72000,
    total_indemnity: 3935,
    individual_base: 2665,
    individual_sbr: 1270,
    individual_group_combo: 4395,
  },
  {
    insurable_income: 74000,
    total_indemnity: 4015,
    individual_base: 2725,
    individual_sbr: 1290,
    individual_group_combo: 4450,
  },
  {
    insurable_income: 76000,
    total_indemnity: 4095,
    individual_base: 2785,
    individual_sbr: 1310,
    individual_group_combo: 4515,
  },
  {
    insurable_income: 78000,
    total_indemnity: 4180,
    individual_base: 2850,
    individual_sbr: 1330,
    individual_group_combo: 4580,
  },
  {
    insurable_income: 80000,
    total_indemnity: 4260,
    individual_base: 2910,
    individual_sbr: 1350,
    individual_group_combo: 4660,
  },
  {
    insurable_income: 85000,
    total_indemnity: 4500,
    individual_base: 3125,
    individual_sbr: 1375,
    individual_group_combo: 4870,
  },
  {
    insurable_income: 90000,
    total_indemnity: 4750,
    individual_base: 3350,
    individual_sbr: 1400,
    individual_group_combo: 5090,
  },
  {
    insurable_income: 95000,
    total_indemnity: 4975,
    individual_base: 3575,
    individual_sbr: 1400,
    individual_group_combo: 5450,
  },
  {
    insurable_income: 100000,
    total_indemnity: 5200,
    individual_base: 3800,
    individual_sbr: 1400,
    individual_group_combo: 5800,
  },
  {
    insurable_income: 120000,
    total_indemnity: 5980,
    individual_base: 4580,
    individual_sbr: 1400,
    individual_group_combo: 7070,
  },
  {
    insurable_income: 140000,
    total_indemnity: 6805,
    individual_base: 5405,
    individual_sbr: 1400,
    individual_group_combo: 8465,
  },
  {
    insurable_income: 160000,
    total_indemnity: 7635,
    individual_base: 6235,
    individual_sbr: 1400,
    individual_group_combo: 9350,
  },
  {
    insurable_income: 180000,
    total_indemnity: 8425,
    individual_base: 7025,
    individual_sbr: 1400,
    individual_group_combo: 10245,
  },
  {
    insurable_income: 200000,
    total_indemnity: 9500,
    individual_base: 8100,
    individual_sbr: 1400,
    individual_group_combo: 11320,
  },
  {
    insurable_income: 220000,
    total_indemnity: 10310,
    individual_base: 8910,
    individual_sbr: 1400,
    individual_group_combo: 12285,
  },
  {
    insurable_income: 240000,
    total_indemnity: 11100,
    individual_base: 9700,
    individual_sbr: 1400,
    individual_group_combo: 13080,
  },
  {
    insurable_income: 260000,
    total_indemnity: 12025,
    individual_base: 10625,
    individual_sbr: 1400,
    individual_group_combo: 14170,
  },
  {
    insurable_income: 280000,
    total_indemnity: 12565,
    individual_base: 11165,
    individual_sbr: 1400,
    individual_group_combo: 15435,
  },
  {
    insurable_income: 300000,
    total_indemnity: 13315,
    individual_base: 11915,
    individual_sbr: 1400,
    individual_group_combo: 16880,
  },
  {
    insurable_income: 320000,
    total_indemnity: 14000,
    individual_base: 12600,
    individual_sbr: 1400,
    individual_group_combo: 18240,
  },
  {
    insurable_income: 340000,
    total_indemnity: 14650,
    individual_base: 13250,
    individual_sbr: 1400,
    individual_group_combo: 19155,
  },
  {
    insurable_income: 360000,
    total_indemnity: 15180,
    individual_base: 13780,
    individual_sbr: 1400,
    individual_group_combo: 19720,
  },
  {
    insurable_income: 380000,
    total_indemnity: 15720,
    individual_base: 14320,
    individual_sbr: 1400,
    individual_group_combo: 20615,
  },
  {
    insurable_income: 400000,
    total_indemnity: 16165,
    individual_base: 14765,
    individual_sbr: 1400,
    individual_group_combo: 21915,
  },
  {
    insurable_income: 420000,
    total_indemnity: 16535,
    individual_base: 15135,
    individual_sbr: 1400,
    individual_group_combo: 23100,
  },
  {
    insurable_income: 440000,
    total_indemnity: 16860,
    individual_base: 15460,
    individual_sbr: 1400,
    individual_group_combo: 24285,
  },
  {
    insurable_income: 460000,
    total_indemnity: 17140,
    individual_base: 15740,
    individual_sbr: 1400,
    individual_group_combo: 25840,
  },
  {
    insurable_income: 480000,
    total_indemnity: 17385,
    individual_base: 15985,
    individual_sbr: 1400,
    individual_group_combo: 26915,
  },
  {
    insurable_income: 500000,
    total_indemnity: 18000,
    individual_base: 16600,
    individual_sbr: 1400,
    individual_group_combo: 28625,
  },
  {
    insurable_income: 520000,
    total_indemnity: 18400,
    individual_base: 17000,
    individual_sbr: 1400,
    individual_group_combo: 29320,
  },
  {
    insurable_income: 540000,
    total_indemnity: 18775,
    individual_base: 17375,
    individual_sbr: 1400,
    individual_group_combo: 29740,
  },
  {
    insurable_income: 560000,
    total_indemnity: 19125,
    individual_base: 17725,
    individual_sbr: 1400,
    individual_group_combo: 30345,
  },
  {
    insurable_income: 580000,
    total_indemnity: 19450,
    individual_base: 18050,
    individual_sbr: 1400,
    individual_group_combo: 30760,
  },
  {
    insurable_income: 600000,
    total_indemnity: 19750,
    individual_base: 18350,
    individual_sbr: 1400,
    individual_group_combo: 31000,
  },
  {
    insurable_income: 620000,
    total_indemnity: 20200,
    individual_base: 18800,
    individual_sbr: 1400,
    individual_group_combo: 31050,
  },
  {
    insurable_income: 640000,
    total_indemnity: 20700,
    individual_base: 19300,
    individual_sbr: 1400,
    individual_group_combo: 31100,
  },
  {
    insurable_income: 660000,
    total_indemnity: 21100,
    individual_base: 19700,
    individual_sbr: 1400,
    individual_group_combo: 31000,
  },
  {
    insurable_income: 680000,
    total_indemnity: 21600,
    individual_base: 20200,
    individual_sbr: 1400,
    individual_group_combo: 30950,
  },
  {
    insurable_income: 700000,
    total_indemnity: 22000,
    individual_base: 20600,
    individual_sbr: 1400,
    individual_group_combo: 30750,
  },
  {
    insurable_income: 720000,
    total_indemnity: 22400,
    individual_base: 21000,
    individual_sbr: 1400,
    individual_group_combo: 31400,
  },
  {
    insurable_income: 740000,
    total_indemnity: 22800,
    individual_base: 21400,
    individual_sbr: 1400,
    individual_group_combo: 35000,
  },
  {
    insurable_income: 760000,
    total_indemnity: 23200,
    individual_base: 21800,
    individual_sbr: 1400,
    individual_group_combo: 35000,
  },
  {
    insurable_income: 780000,
    total_indemnity: 23600,
    individual_base: 22200,
    individual_sbr: 1400,
    individual_group_combo: 35000,
  },
  {
    insurable_income: 800000,
    total_indemnity: 24000,
    individual_base: 22600,
    individual_sbr: 1400,
    individual_group_combo: 35000,
  },
  {
    insurable_income: 820000,
    total_indemnity: 24400,
    individual_base: 23000,
    individual_sbr: 1400,
    individual_group_combo: 35000,
  },
  {
    insurable_income: 840000,
    total_indemnity: 24800,
    individual_base: 23400,
    individual_sbr: 1400,
    individual_group_combo: 35000,
  },
  {
    insurable_income: 860000,
    total_indemnity: 25200,
    individual_base: 23800,
    individual_sbr: 1400,
    individual_group_combo: 35000,
  },
  {
    insurable_income: 880000,
    total_indemnity: 25600,
    individual_base: 24200,
    individual_sbr: 1400,
    individual_group_combo: 35000,
  },
  {
    insurable_income: 900000,
    total_indemnity: 26000,
    individual_base: 24600,
    individual_sbr: 1400,
    individual_group_combo: 35000,
  },
  {
    insurable_income: 920000,
    total_indemnity: 26400,
    individual_base: 25000,
    individual_sbr: 1400,
    individual_group_combo: 35000,
  },
  {
    insurable_income: 940000,
    total_indemnity: 26800,
    individual_base: 25400,
    individual_sbr: 1400,
    individual_group_combo: 35000,
  },
  {
    insurable_income: 960000,
    total_indemnity: 27200,
    individual_base: 25800,
    individual_sbr: 1400,
    individual_group_combo: 35000,
  },
  {
    insurable_income: 980000,
    total_indemnity: 27600,
    individual_base: 26200,
    individual_sbr: 1400,
    individual_group_combo: 35000,
  },
  {
    insurable_income: 1000000,
    total_indemnity: 28000,
    individual_base: 26600,
    individual_sbr: 1400,
    individual_group_combo: 35000,
  },
  {
    insurable_income: 1020000,
    total_indemnity: 28200,
    individual_base: 26800,
    individual_sbr: 1400,
    individual_group_combo: 35000,
  },
  {
    insurable_income: 1040000,
    total_indemnity: 28400,
    individual_base: 27000,
    individual_sbr: 1400,
    individual_group_combo: 35000,
  },
  {
    insurable_income: 1060000,
    total_indemnity: 28600,
    individual_base: 27200,
    individual_sbr: 1400,
    individual_group_combo: 35000,
  },
  {
    insurable_income: 1080000,
    total_indemnity: 28800,
    individual_base: 27400,
    individual_sbr: 1400,
    individual_group_combo: 35000,
  },
  {
    insurable_income: 1100000,
    total_indemnity: 29000,
    individual_base: 27600,
    individual_sbr: 1400,
    individual_group_combo: 35000,
  },
  {
    insurable_income: 1120000,
    total_indemnity: 29200,
    individual_base: 27800,
    individual_sbr: 1400,
    individual_group_combo: 35000,
  },
  {
    insurable_income: 1140000,
    total_indemnity: 29300,
    individual_base: 27900,
    individual_sbr: 1400,
    individual_group_combo: 35000,
  },
  {
    insurable_income: 1160000,
    total_indemnity: 29500,
    individual_base: 28100,
    individual_sbr: 1400,
    individual_group_combo: 35000,
  },
  {
    insurable_income: 1180000,
    total_indemnity: 29600,
    individual_base: 28200,
    individual_sbr: 1400,
    individual_group_combo: 35000,
  },
  {
    insurable_income: 1200000,
    total_indemnity: 30000,
    individual_base: 28600,
    individual_sbr: 1400,
    individual_group_combo: 35000,
  },
  {
    insurable_income: 1220000,
    total_indemnity: 30200,
    individual_base: 28800,
    individual_sbr: 1400,
    individual_group_combo: 35000,
  },
  {
    insurable_income: 1240000,
    total_indemnity: 30500,
    individual_base: 29100,
    individual_sbr: 1400,
    individual_group_combo: 35000,
  },
  {
    insurable_income: 1260000,
    total_indemnity: 30700,
    individual_base: 29300,
    individual_sbr: 1400,
    individual_group_combo: 35000,
  },
  {
    insurable_income: 99999999,
    total_indemnity: 30000,
    individual_base: 28800,
    individual_sbr: 1200,
    individual_group_combo: 30000,
  },
];
