import Page from 'interfaces/Page';
import { PageFlow } from 'interfaces/PageFlow';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getSessionStorageValue,
  setSessionStorageValue,
} from './BrowserStorage';
import { PAGE_FLOW } from 'data/SessionStorageKeys';
import { DiIncomeBand } from 'interfaces/DiIncomeBand';
import { IncomeReplacementChart } from 'data/IncomeReplacementChart';
import { States } from 'data/USStates';
import _ from 'underscore';
import { createRef, useEffect, useRef, useState } from 'react';
import { Client } from 'interfaces/Client';
import { CLIENT_INFO } from 'data/SessionStorageKeys';

export const ClassNames = (...classes: Array<string>): string => {
  return classes.filter(Boolean).join(' ');
};

/**
 * Formats a number into USD.
 *
 * @param {number | string} value - The number to format into USD.
 * @param {number} [numDigits] - Set the decimal digits for the formatted value.
 * @returns {string | number} The formatted number as USD.
 */
export function formatAsCurrency(
  value: number | string,
  numDigits?: number
): string | number {
  if (value === '') {
    return '';
  }
  const number = Number(value);
  if (isNaN(number)) {
    return '';
  }
  const formatNumber = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: numDigits ?? 0,
    maximumFractionDigits: numDigits ?? 0,
  }).format(number);
  return formatNumber;
}

export function formatPhone(value: string): string {
  if (value == undefined) {
    return '';
  }
  // Strip out all non-numeric characters before trying to reformat
  // the phone number
  const cleaned = ('' + value).replace(/\D/g, '');
  return `(${cleaned.substring(0, 3)}) ${cleaned.substring(
    3,
    6
  )}-${cleaned.substring(6, 10)}`;
}

export interface PageFlowState {
  previousPage: Page | undefined;
  currentPage: Page;
  currentSection: string;
  currentIndex: number;
  nextPage: Page | undefined;
}

function findRoute(currentRoute: string, pages: Page[]): number {
  return pages.findIndex((value: Page) => value.slug == currentRoute);
}

function getCurrentPage(
  currentRoute: string,
  pageFlow: PageFlow | undefined
): { currentPage: Page; currentSection: string; currentIndex: number } | null {
  for (const currentSection in pageFlow) {
    const currentIndex = findRoute(currentRoute, pageFlow[currentSection]);
    if (currentIndex >= 0) {
      return {
        currentPage: pageFlow[currentSection][currentIndex],
        currentSection,
        currentIndex,
      };
    }
  }
  return null;
}

/**
 * Custom hook to determine the current, next, and previous pages
 * @returns
 */
export function usePageFlowState(): PageFlowState | undefined {
  const pageFlow = getSessionStorageValue<PageFlow>(PAGE_FLOW);
  const location = useLocation();
  if (pageFlow === undefined) {
    return undefined;
  }
  const { currentPage, currentSection, currentIndex } = {
    ...getCurrentPage(location.pathname, pageFlow),
  };
  if (currentPage === undefined || currentSection === undefined) {
    return undefined;
  }
  const previousPage =
    getPreviousPage(currentPage, currentSection, pageFlow) ?? undefined;
  const nextPage =
    getNextPage(currentPage, currentSection, pageFlow) ?? undefined;
  return {
    previousPage,
    currentPage,
    currentSection,
    currentIndex: currentIndex ?? -1,
    nextPage,
  };
}

export function getNextPage(
  currentPage: Page,
  currentSectionLabel: string,
  pageFlow: PageFlow
): Page {
  const currentSection = pageFlow[currentSectionLabel];
  const indexOfPageInSection = currentSection.findIndex(
    i =>
      i.title === currentPage.title &&
      i.slug === currentPage.slug &&
      i.required === currentPage.required
  );

  if (indexOfPageInSection !== currentSection.length - 1) {
    return currentSection[indexOfPageInSection + 1];
  } else {
    // We're at the end of the section, go to the Report page.
    return {
      title: 'Report',
      slug: '/report',
      required: true,
      completed: false,
      recommendedOrder: 0,
    };
  }
}

export function getPreviousPage(
  currentPage: Page,
  currentSectionLabel: string,
  pageFlow: PageFlow
): Page | void {
  const currentSection = pageFlow[currentSectionLabel];
  const indexOfPageInSection = currentSection.findIndex(
    i =>
      i.title === currentPage.title &&
      i.slug === currentPage.slug &&
      i.required === currentPage.required
  );
  const allSections = Object.keys(pageFlow);
  const currentSectionIndex = allSections.indexOf(currentSectionLabel);

  if (indexOfPageInSection > 0) {
    return currentSection[indexOfPageInSection - 1];
  } else if (currentSectionIndex > 0) {
    const previousSection = allSections[currentSectionIndex - 1];
    const previousSectionLength = pageFlow[previousSection].length;
    return pageFlow[previousSection][previousSectionLength - 1];
  }
}

export function markCurrentPageComplete(
  pageFlowState: PageFlowState | undefined
) {
  const pageFlow = getSessionStorageValue<PageFlow>(PAGE_FLOW);
  if (pageFlow && pageFlowState) {
    pageFlow[pageFlowState.currentSection][
      pageFlowState.currentIndex
    ].completed = true;
  }
  setSessionStorageValue(PAGE_FLOW, pageFlow);
}

export function useNavigateNextInPageFlow(
  pageFlowState: PageFlowState | undefined,
  updateCompleted?: boolean
) {
  const navigate = useNavigate();
  return () => {
    const pageFlow = getSessionStorageValue<PageFlow>(PAGE_FLOW);
    if (pageFlow && pageFlowState && pageFlowState.nextPage) {
      if (updateCompleted ?? true) {
        markCurrentPageComplete(pageFlowState);
      }
      navigate(pageFlowState.nextPage.slug);
    }
  };
}
export function getLowerPayBand(totalSalary: number): DiIncomeBand {
  for (let i = IncomeReplacementChart.length - 1; i >= 0; i--) {
    if (IncomeReplacementChart[i].insurable_income <= totalSalary) {
      return IncomeReplacementChart[i];
    }
  }

  // If we don't find one, return the first band.
  return IncomeReplacementChart[0];
}

export function getUpperPayBand(totalSalary: number): DiIncomeBand {
  for (let i = 0; i < IncomeReplacementChart.length; i++) {
    if (IncomeReplacementChart[i].insurable_income > totalSalary) {
      return IncomeReplacementChart[i];
    }
  }

  // If we don't find one, return the last band.
  return IncomeReplacementChart[IncomeReplacementChart.length - 1];
}

export const roundToNearest5 = (x: number) => Math.round(x / 5) * 5;

/**
 * Calculates the future value of an asset.
 *
 * @param {number} initial - The initial value of the asset.
 * @param {number} rate - The rate of return on the asset given as a decimal.
 * @param {number} years - The number of years on the return.
 * @param {number} [roundToDecimals] - The number of decimal places to round. Optional. Defaults to 2.
 * @returns {number} The future value of the asset.
 */
export function calcFutureValue(
  initial: number,
  rate: number,
  years: number,
  roundToDecimals?: number
): number {
  const futureValue = initial * Math.pow(1 + rate, years);
  const roundTo = roundToDecimals ?? 2;
  return Number(futureValue.toFixed(roundTo));
}

// Calculate income after taxes - this was originally the logic in DI Adjust
// Budget page but moved here so it could be shared in the output.
//
// @param {number} the amount to subtract taxes from
// @returns {number} the amount after taxes
export function calculateAfterTaxIncome(income: number) {
  const taxRate = 0.18;
  const taxes = taxRate * income;
  const value = income - taxes;
  if (isNaN(value)) {
    return 0;
  }
  return value;
}

/**
 * Converts a state abbreviation to its full name.
 *
 * @param {string} abbreviation - The state abbreviation.
 * @returns {string | undefined} The state name if the string entered was found.
 */
export function usStateAbbreviationToName(
  abbreviation: string
): string | undefined {
  const state = _.find(States, element => {
    return element.abbreviation === abbreviation;
  });
  return state?.name;
}

/**
 * Converts a state name to its abbreviation.
 *
 * @param {string} name - The state name.
 * @returns {string | undefined} The state abbreviation if the string entered was found.
 */
export function usStateToAbbreviation(name: string): string | undefined {
  const state = _.find(States, element => {
    return element.name === name;
  });
  return state?.abbreviation;
}

/**
 * This hook allows you to dynamically monitor the width of an HTML element.
 *
 * Use looks like:
 * const [myCompWidth, myCompRef] = useElementWidth<MyCompHTMLElementType>();
 * ...
 * <div ref={myCompRef}.../>
 *
 * @returns a reactive width and a ref to be applied to your component
 */
export function useElementWidth<T extends HTMLElement>() {
  const [width, setWidth] = useState(0);
  const ref = createRef<T>();
  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.getBoundingClientRect().width);
    }
  }, [ref]);
  return { width, ref };
}

/**
 * Create a reference to the previous value of a state. Useful for writing
 * useEffect callbacks that need to compare the before and after of state before
 * doing something.
 *
 * See https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 * for a discussion of why/how this works.
 *
 * @param value the state to monitor
 * @returns the previous value of the state
 */
export function usePreviousState<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/**
 * Calculates how much a client could earn in their lifetime.
 *
 * @param {number} age - The client's age.
 * @param {number} annualBaseIncome - Client's annual base income.
 * @param {number} annualBonusIncome - Client's annual bonus income
 * @returns {number} The amount the client could earn in their lifetime.
 */
export function calculateEarnings(
  age: number,
  annualBaseIncome: number,
  annualBonusIncome: number
): number {
  const retirementAge = 65;
  const rate = 0.3;

  const value =
    annualBaseIncome * ((1 + rate) ^ (retirementAge - age)) +
    annualBonusIncome * (retirementAge - age);

  return value;
}

/**
 * Determines if we visited an optional page added in the defaul page flow.
 *
 * @returns {boolean} Return true if page is visited.
 */
export function visitedPage(slug: string): boolean {
  const pageFlow = getSessionStorageValue<PageFlow>(PAGE_FLOW);
  if (
    pageFlow?.disabilityIncomeInsurance.some(
      i => i.slug === slug && i.completed === true
    ) === true
  ) {
    return true;
  }
  if (
    pageFlow?.lifeInsurance.some(
      i => i.slug === slug && i.completed === true
    ) === true
  ) {
    return true;
  }
  if (
    pageFlow?.longTermCareInsurance.some(
      i => i.slug === slug && i.completed === true
    ) === true
  ) {
    return true;
  }

  return false;
}

export function checkCompletedSections(): [boolean, boolean, boolean] {
  const pageFlow = getSessionStorageValue<PageFlow>(PAGE_FLOW);

  // Check the completed pages in the guide.
  let didCompleteAnyDiPage = false;
  let didCompleteAnyLiPage = false;
  let didCompleteAnyLtcPage = false;
  if (pageFlow !== undefined) {
    if (pageFlow.disabilityIncomeInsurance.some(i => i.completed === true)) {
      didCompleteAnyDiPage = true;
    }
    if (pageFlow.lifeInsurance.some(i => i.completed === true)) {
      didCompleteAnyLiPage = true;
    }
    if (pageFlow.longTermCareInsurance.some(i => i.completed === true)) {
      didCompleteAnyLtcPage = true;
    }
  }

  return [didCompleteAnyDiPage, didCompleteAnyLiPage, didCompleteAnyLtcPage];
}

export function clearBudgetLineItems(values: Client): Client {
  const savedValues =
    getSessionStorageValue<Client>(CLIENT_INFO) ?? ({} as Client);
  delete savedValues.budgetProperty;
  delete savedValues.budgetUtilities;
  delete savedValues.budgetGroceries;
  delete savedValues.budgetAutoExpenses;
  delete savedValues.budgetEducation;
  delete savedValues.budgetOther;
  delete savedValues.budgetSavings;
  delete savedValues.budgetInsurancePremiums;
  delete savedValues.budgetPayments;
  delete savedValues.budgetCharity;
  setSessionStorageValue<Client>(CLIENT_INFO, savedValues);

  delete values.budgetProperty;
  delete values.budgetUtilities;
  delete values.budgetGroceries;
  delete values.budgetAutoExpenses;
  delete values.budgetEducation;
  delete values.budgetOther;
  delete values.budgetSavings;
  delete values.budgetInsurancePremiums;
  delete values.budgetPayments;
  delete values.budgetCharity;
  return values;
}

export const safeNumber = (value: string | number): string => {
  return typeof value === 'number' && !isNaN(value) ? value.toString() : '0';
};
