import { ClassNames } from 'utilities/Utilities';
import { Link, useLocation } from 'react-router-dom';
import {
  ChevronUpIcon,
  PencilIcon,
  CheckCircleIcon,
} from '@heroicons/react/solid';
import { getSessionStorageValue } from 'utilities/BrowserStorage';
import { PageFlow } from 'interfaces/PageFlow';
import { PAGE_FLOW } from 'data/SessionStorageKeys';
import Page from 'interfaces/Page';
import { Disclosure } from '@headlessui/react';

/*
 * Adds the navigation menu to the application. The menu consists of normal style
 * links as well as a mega nav section for the insurance guides.
 *
 * The insurance guides get an edit button which takes you to the configurator, a
 * couple of normal links and then three expander sections for the guides which
 * track your progress via checkmarks.
 *
 * See callers of Utilities.useNavigateNextInPageFlow(...) for an example of how to
 * make your guide page update its progress automagically.
 *
 * There are the following visual states for menu links.
 *  - Normal
 *  - Current route - the link represents the current route of the app
 *  - Hovered - visual display of hovering/focus on the menu item
 */

/**
 * A nav menu represents one layer of navigation menu. It can contain
 * labels, links, or an expandable link section.
 */
interface NavMenuProps {
  children: React.ReactNode;
}

/**
 * A NavLabel is a non-interactable menu entry
 */
interface NavLabelProps {
  name: string;
  className?: string;
  icon?: React.ReactNode;
  iconPosition?: 'start' | 'end';
}

/**
 * A NavLink is a NavLabel you can click on, but you're probably looking for
 * a NavLinkListItem which also wraps your link in list markup.
 */
interface NavLinkProps extends NavLabelProps {
  href: string;
}

/**
 * A NavLinkListItem is a NavLink with surrounding list item markup (used for consistency)
 */
interface NavLinkListItemProps extends NavLinkProps {
  border?: boolean;
}

/**
 * A NavListItem wraps its children with an standardized <li> tag (used for consistency)
 */
interface NavListItemProps {
  border?: boolean;
  className?: string;
  children: React.ReactNode;
}

/**
 * And ExpandableNavSection creates a mega-nav like section in the nav menu that
 * can be expanded or collapsed and contains a sub menu of wonderous things.
 */
interface ExpandableNavSectionProps {
  collapsedHeader: React.ReactNode;
  expandedHeader: React.ReactNode;
  showExpandedMenu?: boolean;
  children?: React.ReactNode;
}

/**
 * A NavAccordion is a menu item that contains an accordion of items.
 */
interface NavAccordionProps {
  name: string;
  pages: Page[];
}

/**
 * An NavAccordionItem is a child of a NavAccordion that displays whether or
 * not a its page has been completed via a checkmark.
 */
interface NavAccordionItemProps {
  title: string;
  slug: string;
  checked: boolean;
  isFirst: boolean;
  isLast: boolean;
}

const NavItemLabel = (props: NavLabelProps) => {
  return (
    <div className="flex flex-row align-baseline gap-2 p-4 -mx-6 text-white">
      {props.icon}
      <span>{props.name}</span>
    </div>
  );
};

const NavLink = (props: NavLinkProps) => {
  const location = useLocation();
  const isCurrentRoute = location.pathname === props.href;

  return (
    <Link
      to={props.href}
      className={ClassNames(
        'block -mx-6 px-6',
        isCurrentRoute
          ? 'font-bold bg-black/20'
          : 'text-white hover:bg-reflectionBlue/10',
        props.className ?? ''
      )}
      aria-current={isCurrentRoute ? 'page' : undefined}
    >
      <NavItemLabel {...props} />
    </Link>
  );
};

const NavLinkListItem = (props: NavLinkListItemProps) => {
  return (
    <NavListItem border={props.border}>
      <NavLink
        className={ClassNames(
          'hover:underline hover:decoration-inherit',
          props.className ?? ''
        )}
        {...props}
      />
    </NavListItem>
  );
};

const NavListItem = (props: NavListItemProps) => {
  return (
    <li
      className={ClassNames(
        props.border ?? true
          ? 'border-b border-solid border-white last:border-none'
          : '',
        props.className ?? ''
      )}
    >
      {props.children}
    </li>
  );
};

function includesRoute(currentRoute: string, pages: Page[]): boolean {
  if (pages.length === 0) {
    return false;
  }
  return pages.some((value: Page) => value.slug == currentRoute);
}

function isInGuide(
  currentRoute: string,
  pageFlow: PageFlow | undefined
): boolean {
  if (pageFlow === undefined) {
    return false;
  }

  return includesRoute(currentRoute, pageFlow.general) ||
    includesRoute(currentRoute, pageFlow.disabilityIncomeInsurance) ||
    includesRoute(currentRoute, pageFlow.lifeInsurance) ||
    includesRoute(currentRoute, pageFlow.longTermCareInsurance)
    ? true
    : false;
}

const ExpandableNavSection = (props: ExpandableNavSectionProps) => {
  const currentPageFlow = getSessionStorageValue<PageFlow>(PAGE_FLOW);
  const location = useLocation();
  const expanded =
    isInGuide(location.pathname, currentPageFlow) ||
    (props.showExpandedMenu ?? false);

  return (
    <>
      {expanded ? (
        <NavListItem>
          {props.expandedHeader}
          {props.children}
        </NavListItem>
      ) : (
        <>{props.collapsedHeader}</>
      )}
    </>
  );
};

const NavMenu = (props: NavMenuProps) => {
  return <ul className="px-6">{props.children}</ul>;
};

const NavAccordionItem = (props: NavAccordionItemProps) => {
  const location = useLocation();
  const isCurrentRoute = location.pathname === props.slug;
  const isFirefox = 'InstallTrigger' in window;

  // So the icon library doesn't have an empty circle icon
  // We simulate that with a div and some custom styling
  return (
    <div className="flex flex-row content-center justify-start">
      <div className="flex-initial flex flex-col gap-0">
        <div
          className={ClassNames(
            'self-center w-0 h-2 flex-grow border-l-2 border-dotted',
            props.isFirst ? 'border-transparent' : ' border-white'
          )}
          style={isFirefox ? {} : { marginTop: '2px' }}
        ></div>
        <div className="w-6 h-6 flex-none text-white">
          {props.checked ? (
            <CheckCircleIcon className="w-6 h-6" />
          ) : (
            <div
              className={ClassNames(
                'rounded-full w-5 h-5 border-2 border-white border-solid',
                isCurrentRoute ? 'bg-white' : 'bg-transparent'
              )}
              style={{ marginLeft: '2px' }}
            ></div>
          )}
        </div>
        <div
          className={ClassNames(
            'self-center w-0 h-2 flex-grow border-l-2 border-dotted',
            props.isLast ? 'border-transparent' : ' border-white'
          )}
          style={{ marginTop: '-2px' }}
        ></div>
      </div>
      <div
        className={ClassNames(
          'block pl-2 text-white self-center flex-grow hover:underline',
          isCurrentRoute
            ? 'font-bold bg-black/20'
            : 'text-white hover:bg-reflectionBlue/10'
        )}
      >
        <Link
          to={props.slug}
          className="block"
          aria-current={isCurrentRoute ? 'page' : undefined}
        >
          <span>{props.title}</span>
        </Link>
      </div>
    </div>
  );
};

const NavAccordion = (props: NavAccordionProps) => {
  const location = useLocation();
  // We could use state here, but a check against the location feels simpler
  const containsCurrentRoute = includesRoute(location.pathname, props.pages);

  return (
    <Disclosure defaultOpen={containsCurrentRoute}>
      {({ open }) => (
        <div className="rounded border-white border-2 mb-2">
          <Disclosure.Button
            className={ClassNames(
              'flex items-center w-full justify-between rounded  text-left text-white bg-navyBlue',
              open ? 'pt-4 pl-4 pr-4 pb-2' : 'p-4'
            )}
          >
            <span className="font-bold">{props.name}</span>
            <ChevronUpIcon
              className={`${
                open ? '' : 'rotate-180 transform'
              } h-5 w-5 text-white`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="flex flex-col px-4">
            {props.pages.map((page, index) => (
              <NavAccordionItem
                key={index}
                checked={page.completed}
                isFirst={index === 0}
                isLast={index === props.pages.length - 1}
                {...page}
              />
            ))}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
};

interface NavigationProps {
  className: string;
  showExpandedMenu?: boolean;
}

export default function Navigation(props: NavigationProps) {
  const currentPageFlow = getSessionStorageValue<PageFlow>(PAGE_FLOW);

  return (
    <nav aria-label="Primary" className={props.className}>
      <NavMenu>
        <NavLinkListItem name="Home" href="/" />
        <ExpandableNavSection
          collapsedHeader={
            <NavLinkListItem
              name="Protection Guide"
              href="/client-information"
            />
          }
          expandedHeader={
            <div className="flex justify-between">
              <NavItemLabel name="Protection Guide" />
              <NavLink
                name="Add / remove pages"
                icon={<PencilIcon className="w-6 h-6 order-last" />}
                href="/configure"
              />
            </div>
          }
          showExpandedMenu={props.showExpandedMenu}
        >
          <NavMenu>
            <NavLinkListItem
              name="Client information"
              href="/client-information"
              border={false}
            />
            <NavLinkListItem
              name="Insurance options"
              href="/choose-your-coverage"
              border={false}
            />

            {currentPageFlow && (
              <div className="mt-2">
                <NavAccordion
                  name="Disability income insurance"
                  pages={currentPageFlow.disabilityIncomeInsurance}
                ></NavAccordion>
                <NavAccordion
                  name="Life insurance"
                  pages={currentPageFlow.lifeInsurance}
                ></NavAccordion>
                <NavAccordion
                  name="Long-term care insurance"
                  pages={currentPageFlow.longTermCareInsurance}
                ></NavAccordion>
              </div>
            )}
            <NavLinkListItem name="Report" href="/report" border={false} />
          </NavMenu>
        </ExpandableNavSection>
        <NavLinkListItem name="Resource library" href="/resource-library" />
      </NavMenu>
    </nav>
  );
}
