/**
 * Retrieves a value for a given key from session storage or stores a default
 * value if none exists.
 *
 * @param {string} key - The key for session storage.
 * @returns {Type} The value stored in the session storage of the browser.
 */
export function getSessionStorageValue<Type>(key: string): Type | undefined {
  const saved = sessionStorage.getItem(key);
  const value: Type = saved === null ? undefined : JSON.parse(saved);
  return value;
}

/**
 * Stores a value for a given key to session storage.
 *
 * @param {string} key - The key for session storage.
 * @param {Type} value - The default value for the session storage key value.
 */
export function setSessionStorageValue<Type>(key: string, value: Type): void {
  sessionStorage.setItem(key, JSON.stringify(value));
}
