import React from 'react';
import Header from 'components/Header/Header';

/**
 * The Default page template renders wrapping components for page content.
 *
 * Expected Props:
 *  title - page title
 *  navButtons - navigation buttons for the page
 *  subtitle - page subtitle, optional
 *  body - introduction content, optional
 *  card - statistical content, optional
 *  children - node list, optional
 *  menuSubtext - text to put in front of the menu, optional
 *  disableNavigation - an optional boolean value that, when true, hides the menu and navigation buttons
 *  showExpandedMenu - an optional boolean value that, when true, will display the main navigation in an expanded state
 */

interface DefaultTemplateProps {
  title: string;
  navButtons?: React.ReactNode;
  subtitle?: React.ReactNode;
  body?: React.ReactNode;
  card?: React.ReactNode;
  children?: React.ReactNode;
  menuSubtext?: React.ReactNode;
  disableNavigation?: boolean;
  showExpandedMenu?: boolean;
}

export default function DefaultTemplate(props: DefaultTemplateProps) {
  const disableNavigation = props.disableNavigation ?? false;

  return (
    <>
      <Header
        menuSubtext={props.menuSubtext}
        disableNavigation={disableNavigation}
        showExpandedMenu={props.showExpandedMenu}
      />
      <div className="row w-full pt-11 pb-36 bg-brandedRipple bg-cover bg-center">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-white">{props.title}</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-16">
            <div>
              {props.subtitle}
              {props.body}
            </div>
            {props.card}
          </div>
        </div>
      </div>
      <div className="row -mt-24">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="block w-full bg-white border border-borderGray py-4 px-6 sm:py-6 sm:px-8 lg:py-8 lg:px-10 rounded">
            <main aria-label="Content">{props.children}</main>
          </div>
        </div>
      </div>
      {!disableNavigation && props.navButtons !== undefined ? (
        <div className="row">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <nav aria-label="Pagination" className="pt-6 pb-10">
              {props.navButtons}
            </nav>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
