import { Link } from 'react-router-dom';
import Overlay from './Overlay';
import logo from 'assets/images/riversource-logo.svg';

interface HeaderTemplateProps {
  menuSubtext?: React.ReactNode;
  disableNavigation?: boolean;
  showExpandedMenu?: boolean;
}

export default function Header(props: HeaderTemplateProps) {
  const disableNavigation = props.disableNavigation ?? false;

  return (
    <>
      <header
        id="header"
        className="header z-40 relative bg-white py-2"
        aria-label="Site"
      >
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-row justify-between items-center h-14">
            <div className="flex-none">
              <Link to="/">
                <img
                  src={logo}
                  alt="Ameriprise Riversource logo"
                  className="w-48"
                />
              </Link>
            </div>
            <div className="flex items-center">
              {props.menuSubtext}
              {disableNavigation ? (
                <></>
              ) : (
                <Overlay showExpandedMenu={props.showExpandedMenu} />
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
