import { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { INITIAL_PAGE_FLOW } from 'data/InitialPageFlow';
import { PageFlow } from 'interfaces/PageFlow';
import { PAGE_FLOW } from 'data/SessionStorageKeys';
import {
  setSessionStorageValue,
  getSessionStorageValue,
} from 'utilities/BrowserStorage';
import DefaultTemplate from 'templates/Default/Default';
import Copyright from 'components/Copyright/Copyright';

// Importing pages
const Home = lazy(() =>
  import('pages/Home/Home').then(({ Home }) => ({ default: Home }))
);
const ClientInformation = lazy(() =>
  import('pages/ClientInformation/ClientInformation').then(
    ({ ClientInformation }) => ({ default: ClientInformation })
  )
);
const ChooseYourCoverage = lazy(() =>
  import('pages/ChooseYourCoverage/ChooseYourCoverage').then(
    ({ ChooseYourCoverage }) => ({ default: ChooseYourCoverage })
  )
);
const Configurator = lazy(() =>
  import('pages/Configurator/Configurator').then(({ Configurator }) => ({
    default: Configurator,
  }))
);
const ConfigureOrder = lazy(() =>
  import('pages/Configurator/ConfigureOrder').then(({ ConfigureOrder }) => ({
    default: ConfigureOrder,
  }))
);
const Intro = lazy(() =>
  import('pages/DisabilityIncomeInsurance/Intro/Intro').then(({ Intro }) => ({
    default: Intro,
  }))
);
const AdjustBudget = lazy(() =>
  import('pages/DisabilityIncomeInsurance/AdjustBudget/AdjustBudget').then(
    ({ AdjustBudget }) => ({ default: AdjustBudget })
  )
);
const CausesForDisability = lazy(() =>
  import(
    'pages/DisabilityIncomeInsurance/CausesForDisability/CausesForDisability'
  ).then(({ CausesForDisability }) => ({ default: CausesForDisability }))
);
const DICheckCoverage = lazy(() =>
  import('pages/DisabilityIncomeInsurance/CheckCoverage/CheckCoverage').then(
    ({ CheckCoverage }) => ({ default: CheckCoverage })
  )
);
const ImpactToRetirementSavings = lazy(() =>
  import(
    'pages/DisabilityIncomeInsurance/ImpactToRetirementSavings/ImpactToRetirementSavings'
  ).then(({ ImpactToRetirementSavings }) => ({
    default: ImpactToRetirementSavings,
  }))
);
const OptionalSourcesOfIncome = lazy(() =>
  import(
    'pages/DisabilityIncomeInsurance/OptionalSourcesOfIncome/OptionalSourcesOfIncome'
  ).then(({ OptionalSourcesOfIncome }) => ({
    default: OptionalSourcesOfIncome,
  }))
);
const ProtectIncome = lazy(() =>
  import('pages/DisabilityIncomeInsurance/ProtectIncome/ProtectIncome').then(
    ({ ProtectIncome }) => ({ default: ProtectIncome })
  )
);
const ProvidingForFamily = lazy(() =>
  import(
    'pages/DisabilityIncomeInsurance/ProvidingForFamily/ProvidingForFamily'
  ).then(({ ProvidingForFamily }) => ({ default: ProvidingForFamily }))
);
const ValueFutureIncome = lazy(() =>
  import(
    'pages/DisabilityIncomeInsurance/ValueFutureIncome/ValueFutureIncome'
  ).then(({ ValueFutureIncome }) => ({ default: ValueFutureIncome }))
);
const LICheckCoverage = lazy(() =>
  import('pages/LifeInsurance/CheckCoverage/CheckCoverage').then(
    ({ CheckCoverage }) => ({ default: CheckCoverage })
  )
);
const DifferentLifeStages = lazy(() =>
  import('pages/LifeInsurance/DifferentLifeStages/DifferentLifeStages').then(
    ({ DifferentLifeStages }) => ({ default: DifferentLifeStages })
  )
);
const LIInsuranceSolutions = lazy(() =>
  import('pages/LifeInsurance/InsuranceSolutions/InsuranceSolutions').then(
    ({ InsuranceSolutions }) => ({ default: InsuranceSolutions })
  )
);
const LegacyForGenerations = lazy(() =>
  import('pages/LifeInsurance/LegacyForGenerations/LegacyForGenerations').then(
    ({ LegacyForGenerations }) => ({ default: LegacyForGenerations })
  )
);
const TaxControlTriangle = lazy(() =>
  import('pages/LifeInsurance/TaxControlTriangle/TaxControlTriangle').then(
    ({ TaxControlTriangle }) => ({ default: TaxControlTriangle })
  )
);
const WhatAreLivingBenefits = lazy(() =>
  import(
    'pages/LifeInsurance/WhatAreLivingBenefits/WhatAreLivingBenefits'
  ).then(({ WhatAreLivingBenefits }) => ({ default: WhatAreLivingBenefits }))
);
const FundingSources = lazy(() =>
  import('pages/LongTermCareInsurance/FundingSources/FundingSources').then(
    ({ FundingSources }) => ({ default: FundingSources })
  )
);
const LTCInsuranceSolutions = lazy(() =>
  import(
    'pages/LongTermCareInsurance/InsuranceSolutions/InsuranceSolutions'
  ).then(({ InsuranceSolutions }) => ({ default: InsuranceSolutions }))
);
const LongTermCarePlan = lazy(() =>
  import('pages/LongTermCareInsurance/LongTermCarePlan/LongTermCarePlan').then(
    ({ LongTermCarePlan }) => ({ default: LongTermCarePlan })
  )
);
const LongTermCareManagement = lazy(() =>
  import(
    'pages/LongTermCareInsurance/LongTermCareManagement/LongTermCareManagement'
  ).then(({ LongTermCareManagement }) => ({ default: LongTermCareManagement }))
);
const PlanningForLongTermCare = lazy(() =>
  import(
    'pages/LongTermCareInsurance/PlanningForLongTermCare/PlanningForLongTermCare'
  ).then(({ PlanningForLongTermCare }) => ({
    default: PlanningForLongTermCare,
  }))
);
const PotentialCostOfCare = lazy(() =>
  import(
    'pages/LongTermCareInsurance/PotentialCostOfCare/PotentialCostOfCare'
  ).then(({ PotentialCostOfCare }) => ({ default: PotentialCostOfCare }))
);
const Report = lazy(() =>
  import('pages/Report/Report').then(({ Report }) => ({ default: Report }))
);
const ResourceLibrary = lazy(() =>
  import('pages/ResourceLibrary/ResourceLibrary').then(
    ({ ResourceLibrary }) => ({
      default: ResourceLibrary,
    })
  )
);
const NotFound = lazy(() =>
  import('pages/NotFound/NotFound').then(({ NotFound }) => ({
    default: NotFound,
  }))
);

const LoadingScreen = () => (
  <DefaultTemplate title="Loading...">Loading...</DefaultTemplate>
);

export default function App() {
  // Load the initial state of the application if none exist.
  let currentPageFlow = getSessionStorageValue<PageFlow>(PAGE_FLOW);
  if (currentPageFlow === undefined) {
    setSessionStorageValue<PageFlow>(PAGE_FLOW, INITIAL_PAGE_FLOW);
    currentPageFlow = INITIAL_PAGE_FLOW;
  }

  const resoureLibraryQueryParam = new URLSearchParams(
    window.location.search
  ).get('resourceLibrary');
  const comingFromResourceLibrary =
    resoureLibraryQueryParam === 't' ? true : false;

  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<LoadingScreen />}>
          <div className="min-h-full">
            <Routes>
              {/* General pages */}
              <Route path="/" element={<Home />} />
              <Route path="/configure" element={<Configurator />} />
              <Route path="/configure-order" element={<ConfigureOrder />} />
              <Route
                path="/client-information"
                element={<ClientInformation />}
              />
              <Route
                path="/choose-your-coverage"
                element={<ChooseYourCoverage />}
              />
              {/* Disability income insurance pages */}
              <Route
                path="/di-intro"
                element={
                  <Intro
                    disableNavigation={comingFromResourceLibrary}
                    showResourceLibraryPrintButton={comingFromResourceLibrary}
                  />
                }
              />
              <Route
                path="/di-adjust-budget"
                element={
                  <AdjustBudget
                    disableNavigation={comingFromResourceLibrary}
                    showResourceLibraryPrintButton={comingFromResourceLibrary}
                  />
                }
              />
              <Route
                path="/di-causes-for-disability"
                element={
                  <CausesForDisability
                    disableNavigation={comingFromResourceLibrary}
                    showResourceLibraryPrintButton={comingFromResourceLibrary}
                  />
                }
              />
              <Route
                path="/di-check-coverage"
                element={
                  <DICheckCoverage
                    disableNavigation={comingFromResourceLibrary}
                    showResourceLibraryPrintButton={comingFromResourceLibrary}
                  />
                }
              />
              <Route
                path="/di-impact-to-retirement-savings"
                element={
                  <ImpactToRetirementSavings
                    disableNavigation={comingFromResourceLibrary}
                    showResourceLibraryPrintButton={comingFromResourceLibrary}
                  />
                }
              />
              <Route
                path="/di-optional-sources-of-income"
                element={
                  <OptionalSourcesOfIncome
                    disableNavigation={comingFromResourceLibrary}
                    showResourceLibraryPrintButton={comingFromResourceLibrary}
                  />
                }
              />
              <Route
                path="/di-protect-income"
                element={
                  <ProtectIncome
                    disableNavigation={comingFromResourceLibrary}
                  />
                }
              />
              <Route
                path="/di-providing-for-family"
                element={
                  <ProvidingForFamily
                    disableNavigation={comingFromResourceLibrary}
                  />
                }
              />
              <Route
                path="/di-value-future-income"
                element={
                  <ValueFutureIncome
                    disableNavigation={comingFromResourceLibrary}
                  />
                }
              />
              {/* Life insurance pages */}
              <Route
                path="/li-check-coverage"
                element={
                  <LICheckCoverage
                    disableNavigation={comingFromResourceLibrary}
                    showResourceLibraryPrintButton={comingFromResourceLibrary}
                  />
                }
              />
              <Route
                path="/li-different-life-stages"
                element={
                  <DifferentLifeStages
                    disableNavigation={comingFromResourceLibrary}
                    showResourceLibraryPrintButton={comingFromResourceLibrary}
                  />
                }
              />
              <Route
                path="/li-insurance-solutions"
                element={
                  <LIInsuranceSolutions
                    disableNavigation={comingFromResourceLibrary}
                    showResourceLibraryPrintButton={comingFromResourceLibrary}
                  />
                }
              />
              <Route
                path="/li-legacy-for-generations"
                element={
                  <LegacyForGenerations
                    disableNavigation={comingFromResourceLibrary}
                  />
                }
              />
              <Route
                path="/li-tax-control-triangle"
                element={
                  <TaxControlTriangle
                    disableNavigation={comingFromResourceLibrary}
                    showResourceLibraryPrintButton={comingFromResourceLibrary}
                  />
                }
              />
              <Route
                path="/li-what-are-living-benefits"
                element={
                  <WhatAreLivingBenefits
                    disableNavigation={comingFromResourceLibrary}
                  />
                }
              />
              {/* Long-term care insurance pages */}
              <Route
                path="/ltc-funding-sources"
                element={
                  <FundingSources
                    disableNavigation={comingFromResourceLibrary}
                    showResourceLibraryPrintButton={comingFromResourceLibrary}
                  />
                }
              />
              <Route
                path="/ltc-insurance-solutions"
                element={
                  <LTCInsuranceSolutions
                    disableNavigation={comingFromResourceLibrary}
                    showResourceLibraryPrintButton={comingFromResourceLibrary}
                  />
                }
              />
              <Route
                path="/ltc-long-term-care-plan"
                element={
                  <LongTermCarePlan
                    disableNavigation={comingFromResourceLibrary}
                    showResourceLibraryPrintButton={comingFromResourceLibrary}
                  />
                }
              />
              <Route
                path="/ltc-long-term-care-management"
                element={
                  <LongTermCareManagement
                    disableNavigation={comingFromResourceLibrary}
                  />
                }
              />
              <Route
                path="/ltc-planning-for-long-term-care"
                element={
                  <PlanningForLongTermCare
                    disableNavigation={comingFromResourceLibrary}
                  />
                }
              />
              <Route
                path="/ltc-potential-cost-of-care"
                element={
                  <PotentialCostOfCare
                    disableNavigation={comingFromResourceLibrary}
                    showResourceLibraryPrintButton={comingFromResourceLibrary}
                  />
                }
              />
              {/* Report page */}
              <Route path="/report" element={<Report />} />
              {/* Resource Library page */}
              <Route path="/resource-library" element={<ResourceLibrary />} />
              {/* 404 Not Found */}
              <Route path="*" element={<NotFound />} />
            </Routes>
            <div className="container mx-auto px-8 text-sm mb-8 print:hidden">
              <Copyright />
            </div>
          </div>
        </Suspense>
      </BrowserRouter>
    </>
  );
}
