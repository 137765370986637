import { Fragment, useState, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/solid';

import Navigation from './Navigation';

interface OverlayProps {
  showExpandedMenu?: boolean;
}

export default function Overlay(props: OverlayProps) {
  const [isOpen, setIsOpen] = useState(false);
  const closeButtonRef = useRef(null);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <button
        type="button"
        onClick={openModal}
        className="bg-darkReflectionBlue px-3 py-2 text-center text-sm font-medium text-white rounded hover:bg-reflectionBlue focus:bg-reflectionBlue"
        aria-controls="headerOverlay"
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        <MenuIcon className="h-5 w-5 mx-auto text-white" />
        <span className="sr-only">Open</span> Menu
      </button>
      <Transition show={isOpen} as={Fragment}>
        <Dialog
          id="headerOverlay"
          className="relative z-50"
          onClose={closeModal}
          initialFocus={closeButtonRef}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex justify-end min-h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Panel className="w-full md:max-w-md transform transition-all overflow-hidden bg-navyBlue pb-8 shadow-xl relative">
                  <button
                    type="button"
                    className="my-2 absolute top-0 right-8 bg-darkReflectionBlue hover:bg-reflectionBlue focus:bg-reflectionBlue px-3 py-2 text-center font-bold text-sm text-white rounded"
                    onClick={closeModal}
                    ref={closeButtonRef}
                  >
                    <XIcon className="h-5 w-5 mx-auto text-white" />
                    Close <span className="sr-only">Menu</span>
                  </button>

                  <Navigation
                    className="mt-16 pt-2"
                    showExpandedMenu={props.showExpandedMenu}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
