import { PageFlow } from 'interfaces/PageFlow';

export const INITIAL_PAGE_FLOW: PageFlow = {
  general: [
    {
      title: 'Client Information',
      slug: '/client-information',
      required: true,
      completed: false,
      recommendedOrder: 0,
    },
    {
      title: 'Choose Your Coverage',
      slug: '/choose-your-coverage',
      required: true,
      completed: false,
      recommendedOrder: 1,
    },
  ],
  disabilityIncomeInsurance: [
    {
      title: 'Calculator: Value of your future income',
      slug: '/di-intro',
      required: true,
      completed: false,
      recommendedOrder: 0,
    },
    {
      title: 'Adjust your budget for "What if" scenarios',
      slug: '/di-adjust-budget',
      required: true,
      completed: false,
      recommendedOrder: 2,
    },
    {
      title: 'Calculator: Check your coverage',
      slug: '/di-check-coverage',
      required: true,
      completed: false,
      recommendedOrder: 3,
    },
    {
      title: 'Optional sources for income',
      slug: '/di-optional-sources-of-income',
      required: true,
      completed: false,
      recommendedOrder: 6,
    },
  ],
  lifeInsurance: [
    {
      title: 'Life insurance for different life stages',
      slug: '/li-different-life-stages',
      required: true,
      completed: false,
      recommendedOrder: 0,
    },
    {
      title: 'Calculator: Check your coverage',
      slug: '/li-check-coverage',
      required: true,
      completed: false,
      recommendedOrder: 1,
    },
    {
      title: 'Insurance solutions',
      slug: '/li-insurance-solutions',
      required: true,
      completed: false,
      recommendedOrder: 2,
    },
  ],
  longTermCareInsurance: [
    {
      title: 'Your long-term care plan',
      slug: '/ltc-long-term-care-plan',
      required: true,
      completed: false,
      recommendedOrder: 0,
    },
    {
      title: 'Types of care settings',
      slug: '/ltc-long-term-care-management',
      required: true,
      completed: false,
      recommendedOrder: 1,
    },
    {
      title: 'Calculator: Potential cost of care',
      slug: '/ltc-potential-cost-of-care',
      required: true,
      completed: false,
      recommendedOrder: 3,
    },
    {
      title: 'Funding sources',
      slug: '/ltc-funding-sources',
      required: true,
      completed: false,
      recommendedOrder: 4,
    },
    {
      title: 'Insurance solutions',
      slug: '/ltc-insurance-solutions',
      required: true,
      completed: false,
      recommendedOrder: 5,
    },
  ],
};
